@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';

.lb_promoBanner-global {
    margin: 0 auto layout.$lineHeight;
    padding: 0;
    height: 58px;

    .lb_innerPageSection {
        display: grid;
        padding: 0;
        max-width: unset;
        grid-template-columns: min-content 117px;
        justify-content: center;
        grid-template-areas:
            "heading sign"
            "timer sign";

            @include breakpoints.respond-to("tablet") {
                display: flex;
                align-items: center;
                justify-content: center;
            }

        .lb_promoBanner_heading {
            grid-area: heading;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 29px;
            img,
            source {
                height: 29px;
                width: auto;
            }
            
            @include breakpoints.respond-to("tablet") {
                width: auto;
                align-items: center;

                img {
                    height: 58px;
                    width: auto;
                }
            }
        }

        .lb_promoBanner_timer {
            grid-area: timer;
            display: flex;
            justify-content: center;
            padding-left: 8px;
            padding-right: 8px;
            height: 26px;
            font-size: .9rem;

            @include breakpoints.respond-to("tablet") {
                padding-left: 0;
                padding-right: 0;
                align-items: center;
                flex-wrap: nowrap;
            }
            
            @include breakpoints.respond-to("desktop") {
                font-size: 1.2rem;
            }

            .lb_promoBanner_timer_altText {
                font-size: 1rem;
                font-weight: fontWeights.$fontWeightSemiBold;
                line-height: 1;
                text-wrap: nowrap;

                @include breakpoints.respond-to("tablet") {
                    font-size: 1.4rem;
                }

                @include breakpoints.respond-to("desktop") {
                    font-size: 1.6rem;
                }
            }
        }

        .lb_promoBanner_sign {
            grid-area: sign;
            display: flex;
            align-items: flex-start;
            width: 109px;
            height: 58px;
            @include breakpoints.respond-to("tablet") {
                margin-right: 4px;
            }
        }
    }
}