@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';

.lb_promoBanner-homePage {
  height: 180px;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;

  @include breakpoints.respond-to("desktop") {
    background-size: 1900px;
  }

  .lb_innerPageSection {
    height: 100%;
    width: 100%;
  }

  .lb_promoBanner_wrapper {
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lb_promoBanner_heading {
    margin-bottom: 12px;
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 120px; 
      @include breakpoints.respond-to("desktop") {
        max-height: 160px;
      }
    }  
  }

  .lb_promoBanner_timer {
    margin-bottom: 12px;

    .lb_countdown {
      font-size: .9rem;
      @include breakpoints.respond-to("desktop") {
        font-size: 1rem
      }
    }

    .lb_countdownAlternative {
      font-size: 1.2rem;
      line-height: 1;
    }
  }
  
  .lb_promoBanner_button {
    grid-area: button;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: .8rem !important;

    span {
      display: flex;
      height: 28px;
      border-radius: 5px;
      padding: 0 16px;
      align-items: center;
      font-size: .9rem;
      font-weight: fontWeights.$fontWeightBold;

      @include breakpoints.respond-to("desktop") {
        font-size: 1.1rem;
      }
    }
  }
}