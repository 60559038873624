@use '/src/scss/promo/promoBanner/promoBannerHomePage.scss';
@use '/src/scss/promo/promoBanner/promoBannerGlobal.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/promo/backgroundEffects/_bokahValentines.scss';

$promoBackgroundColourPrimary: #b73a64;
$promoBackgroundColourSecondary: #EDCC67;
$promoTextColour: #ffffff;
$promoButtonBackgroundColour: #ffffff;
$promoButtonTextColour: #b73a64;

.lb_promoBanner-productPage.lb_promoBanner-valentinesSale {
    img {
        height: 18px !important;
        @include breakpoints.respond-to("desktop") {
            height: 15px !important;
        }
    }
}

.lb_promoBanner-homePage.lb_promoBanner-valentinesSale {
    height: 240px !important;

    @include breakpoints.respond-to("tablet") {
        height: 290px !important;
    }

    .lb_promoBanner_wrapper {
        gap: 0px !important;
    }

    img {
        max-height: 100px !important;
        @include breakpoints.respond-to("tablet") {
            max-height: 140px !important;
        }
    }

    .lb_countdownAlternative,
    .lb_countdown {
        color: $promoTextColour !important;
    }

    .lb_promoBanner_button {

        span {
            background-color: $promoButtonBackgroundColour;
            color: $promoButtonTextColour;
        }
    }
}

.lb_promoBanner-global.lb_promoBanner-valentinesSale {
    position: relative;

    .lb_promoBanner_timer_altText,
    .lb_promoBanner_timer {
        color: $promoTextColour !important;
    }
}