.lb_backgroundEffects-bokahValentines {
  position: relative;  overflow: hidden;
  width: 100%;

}

.lb_backgroundEffects-bokahValentines > div {
  background-color: #B73A64;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;}

.lb_backgroundEffects-bokahValentines > div span {
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $bokahValentines-colors: (
    #ff99f755,
    #ffcceb55,
    #fc7fb355
  );
  @for $bokahValentines-i from 1 through 15 {
    &:nth-child(#{$bokahValentines-i}) {
      // Set height and width using padding
      $bokahValentines-randomPercent: random(15) + 0px;
      padding: calc(3% + $bokahValentines-randomPercent);

      border-radius: 50%;
      background-color: nth($bokahValentines-colors, random(length($bokahValentines-colors)));

      //Set positions with a bit of bleed
      top: -20% + random(120);
      left: -20% + random(120);
      filter: blur(random(10)+1px);
    }
  }
  
  // Moving elements
  @for $bokahValentines-i from 16 through 30 {
    &:nth-child(#{$bokahValentines-i}) {

     //Loop through colours
      color: nth($bokahValentines-colors, random(length($bokahValentines-colors)));
      
      //Set positions with a bit of bleed
      top: -20% + random(120);
      left: -20% + random(120);

      //Set animation speed
      animation-duration: random(100) + 99s;
      animation-delay: random(1) * 2s;

      //Random offset centre of particles for animation
      transform-origin: random(25) * .5vw random(25) * 1vh;

      //Set particle size
      $bokahValentines-randomPercent: random(15) + 1px;
      padding: calc(3% + $bokahValentines-randomPercent);
      border-radius: 1000px;

      //Set random blur
      $bokahValentines-x: if(random() > 0.5, -1, 1); //Split the offset blur left or right to fill the page
      $bokahValentines-blurRadius: random(1) + 1px;
      box-shadow: ($bokahValentines-x * (190px)) 0 $bokahValentines-blurRadius currentColor;
    }
    &:nth-child(3n+16) {
      //Load image
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve"><defs></defs><g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" ><path d="M 45 10.715 c 4.77 -4.857 11.36 -7.861 18.64 -7.861 C 78.198 2.854 90 14.87 90 29.694 c 0 35.292 -36.812 34.15 -45 57.453 C 36.812 63.843 0 64.986 0 29.694 C 0 14.87 11.802 2.854 26.36 2.854 C 33.64 2.854 40.23 5.858 45 10.715 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,200,230); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /></g></svg>');
      background-size: contain;
      border-radius: 42%;
      opacity: .5;
      z-index: 10;
      background-color: transparent;
      filter: blur(0);
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}